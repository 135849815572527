import React, { Children } from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Sidebar from '../components/sidebar'

const Projects = ({ data }) => {

	const SidebarCol = styled.div`
		grid-column: 2 / span 3;
		h1{
			f
		}
		@media screen and (max-width: 1023px){
			display: none;
		}
	`
	const TilList = styled.div`
		grid-column: 6 / span 6;
		grid-row: 1 / 4;
		@media screen and (max-width: 767px){
			grid-column: 1 / span 4;
		}
		@media screen and (min-width: 768px) and (max-width: 1023px){
			grid-column: 2 / span 6;
		}
	`
	const TilItem = styled.div`
		margin-bottom: 1.5rem;
		a{
			text-decoration: none;
		}
		&:hover{
			a{
				text-shadow: -2px 5px 0 var(--c-secondary);
			}
		}
	`
	const TilTitle = styled.h2`
    font-size: 2rem;
    margin: 0.5rem 0 2rem;
		color:black;
			text-decoration: none;
			transition: 300ms;
    }
    @media screen and (max-width: 767px){
        font-size: 1.5rem;
    }       
	`
	const TilDate = styled.h6`
		font-size: 0.75em;
		font-weight: normal;
		color: var(--c-grey);
		margin: 0;
  `

	const { edges: projects } = data.allMdx

	return (
		<Layout>
			<SEO 
				pageTitle="Today I Learned" 
				pageDesc="I put some notes about everything that I just learned."
			/>
			<div className="container">
				<SidebarCol><Sidebar headingText="I am a stupid person" /></SidebarCol>
				<TilList>
					{projects.map(({ node: project }) => (
					<TilItem key={project.id}>
						<TilDate>{project.frontmatter.date}</TilDate>
						<Link to={project.frontmatter.path}>
							<TilTitle>{project.frontmatter.title}</TilTitle>
						</Link>
					</TilItem>
					))}
				</TilList>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
  query tilIndex {
		allMdx(sort: {order: DESC, fields: frontmatter___date}) {
	  edges {
		node {
		  id
		  excerpt
		  frontmatter {
				title
				path
				date(formatString: "MMMM Do, YYYY")
		  }
		}
	  }
	}
  }
`

export default Projects

